const invoice = {
  photograph: null,
  firstName: null,
  surname: null,
  middleName: null,
  phone: null,
  nin: null,
  confirmNin: null,
  vNin: null,
  email: null,
  townCode: null,
  areaCode: null,
  streetCode: null,
  stepDone: 0,
};

export default {
  namespaced: true,
  state: {
    overlay: false,
    loggedIn: false,
    sideOpen: false,
    user: null,
    step: 1,
    showForm: false,
    invoiceData: { ...invoice },
    receiptData: { ...invoice },
    revenueItems: [],
  },
  getters: {
    ind(state) {
      return state.invoiceData;
    },
    recp(state) {
      return state.receiptData;
    },
    revenues(state) {
      return state.revenueItems;
    },
  },
  mutations: {
    toggleNavBar(state, status = "q") {
      if (status != "q") {
        state.sideOpen = status;
      } else {
        state.sideOpen = !state.sideOpen;
      }
    },
    saveUser(state, { user, token }) {
      if (token) localStorage.setItem(process.env.VUE_APP_tokenName, token);

      state.user = user;
    },
    updateInvoiceData(state, data) {
      state.invoiceData = { ...state.invoiceData, ...data };
    },
    wipeInvoice(state) {
      state.invoiceData = { ...invoice };
      state.revenueItems = [];
    },
    updateReceiptData(state, data) {
      state.receiptData = { ...state.receiptData, ...data };
    },
    wipeReceipt(state) {
      state.receiptData = { ...invoice };
      state.step = 1;
    },
    addRevenueItems(state, data) {
      state.revenueItems.push(data);
    },
    delRevenueItem(state, id) {
      state.revenueItems = state.revenueItems.filter((_, idx) => idx != id);
    },
    updateStep(state, step) {
      state.step = step;
    },
    updateForm(state, val) {
      state.showForm = val;
    },
    login(state) {
      state.loggedIn = true;
    },
    logout(state) {
      state.loggedIn = false;
      localStorage.removeItem(process.env.VUE_APP_tokenName);

      // clear state
      Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
          state[key] = [];
        } else {
          state[key] = false;
        }
      });

      localStorage.removeItem(process.env.VUE_APP_appDB);
      localStorage.clear();
    },
  },
  actions: {},
};
